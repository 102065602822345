import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChildrenDealer, Dealer, DealerUser } from '../../../shared/interfaces/dealer.interfaces';

export const DealerActions = createActionGroup({
  source: 'Dealer',
  events: {
    'Load Dealer': emptyProps(),
    'Load Dealer Success': props<{ data: Dealer[] }>(),
    'Load Dealer Failure': props<{ error: unknown }>(),

    'Load Users': emptyProps(),
    'Load Users Success': props<{ data: DealerUser[] }>(),
    'Load Users Failure': props<{ error: unknown }>(),
    'Unset Users': emptyProps(),

    'Load Children Dealers': emptyProps(),
    'Load Children Dealers Success': props<{ data: ChildrenDealer[] | [] }>(),
    'Load Children Dealers Failure': props<{ error: unknown }>(),
    'Unset Children Dealers': emptyProps(),

    'Send New Dealer Request': props<( { payload: any})>(),
    'Send New Dealer Request Success': props<{ data: any }>(),
    'Send New Dealer Request Failure': props<{ error: unknown }>(),
    
    'Unset Dealer': emptyProps(),
  }
});
